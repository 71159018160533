import React, { Fragment, memo } from 'react';
import { css } from '@emotion/react';
import { NavigationItem } from '~/common/components/layout/navigation/NavigationItem';
import { NavigationSecondItem } from '~/common/components/layout/navigation/NavigationSecondItem';
import { NavigationStatus } from '~/common/components/layout/navigation/navigation-control';
import { NavigationSecondToggleItem } from '~/common/components/layout/navigation/NavigationSecondToggleItem';
import { NavigationThirdItem } from '~/common/components/layout/navigation/NavigationThirdItem';

type NavigationGroup = {
  type: 'group';
  key: string;
  children: NavigationLink[];
} & Omit<React.ComponentProps<typeof NavigationSecondToggleItem>, 'children'>;

type NavigationLink = {
  type?: 'link';
  id?: string;
  key: string;
} & React.ComponentProps<typeof NavigationSecondItem>;

type NavigationMenuItem = {
  key: string;
  children: (NavigationGroup | NavigationLink)[];
} & Omit<React.ComponentProps<typeof NavigationItem>, 'isClose' | 'children'>;

type NavigationBodyProps = {
  items: NavigationMenuItem[];
  navigationStatus: NavigationStatus;
};

const NavigationBody = memo(function NavigationBody({
  items,
  navigationStatus,
}: NavigationBodyProps) {
  const isClose = navigationStatus === 'CLOSE';
  const Separator = () => (!isClose ? <hr css={styles.separator} /> : <></>);

  return (
    <div css={styles.container}>
      <div css={styles.itemList}>
        {items.map((item, idx) => {
          return (
            <Fragment key={item.key}>
              {idx > 0 && <Separator />}
              <NavigationItem key={item.key} text={item.text} icon={item.icon} isClose={isClose}>
                {item.children.map((secondItem) => {
                  if (secondItem.type === 'group') {
                    return (
                      <NavigationSecondToggleItem
                        key={secondItem.key}
                        text={secondItem.text}
                        id={secondItem.id}
                      >
                        {secondItem.children.map((thirdItem) => (
                          <NavigationThirdItem
                            key={thirdItem.key}
                            id={thirdItem.id}
                            text={thirdItem.text}
                            targetUrl={thirdItem.targetUrl}
                            isActive={thirdItem.isActive}
                            suffix={thirdItem.suffix}
                          />
                        ))}
                      </NavigationSecondToggleItem>
                    );
                  }
                  return (
                    <NavigationSecondItem
                      key={secondItem.key}
                      id={secondItem.id}
                      text={secondItem.text}
                      targetUrl={secondItem.targetUrl}
                      isActive={secondItem.isActive}
                      suffix={secondItem.suffix}
                    />
                  );
                })}
              </NavigationItem>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
});

const styles = {
  container: css`
    width: 100%;
    height: calc(100vh - 61px);
    padding: var(--spacing-3);
    padding-bottom: 80px; // Onboarding ボタンとの重なりを防ぐため
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
  `,
  itemList: css`
    width: 240px;
  `,
  separator: css`
    margin: 1px 0 var(--spacing-2);
    border: none;
    border-top: solid 1px var(--color-neutral-3);
  `,
};

export { NavigationBody };
export type { NavigationBodyProps, NavigationMenuItem };
