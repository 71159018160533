import { css, SerializedStyles } from '@emotion/react';
import { assertNever } from '~/common/utils/assertNever';

const BUTTON_VARIANT = ['primary', 'secondary', 'danger'] as const;
type ButtonVariant = (typeof BUTTON_VARIANT)[number];

const BUTTON_APPEARANCE = ['fill', 'outline', 'ghost'] as const;
type ButtonAppearance = (typeof BUTTON_APPEARANCE)[number];

const buttonVariantStyle = (
  variant: ButtonVariant,
  appearance: ButtonAppearance,
): SerializedStyles => {
  const variantAppearance = `${variant}_${appearance}` as const;
  switch (variantAppearance) {
    case 'primary_fill':
      return styles.buttonPrimaryFill;
    case 'primary_outline':
      return styles.buttonPrimaryOutline;
    case 'primary_ghost':
      return styles.buttonPrimaryGhost;
    case 'secondary_fill':
    case 'secondary_outline':
      return styles.buttonSecondaryOutline;
    case 'secondary_ghost':
      return styles.buttonSecondaryGhost;
    case 'danger_fill':
      return styles.buttonDangerFill;
    case 'danger_outline':
      return styles.buttonDangerOutline;
    case 'danger_ghost':
      return styles.buttonDangerGhost;
    default:
      return assertNever(variantAppearance);
  }
};

const styles = {
  buttonPrimaryFill: css`
    background-color: var(--color-primary-6);
    border-color: var(--color-primary-6);
    color: var(--color-neutral-1);

    &:hover:not(:disabled) {
      background-color: var(--color-primary-7);
      border-color: var(--color-primary-7);
      box-shadow: 0 0 0 2px var(--color-primary-2);
    }

    &:active {
      background-color: var(--color-primary-6);
      border-color: var(--color-primary-6);
    }

    &:focus {
      background-color: var(--color-primary-6);
      border-color: var(--color-primary-6);
      box-shadow:
        0 0 0 1px var(--color-neutral-0),
        0 0 0 3px var(--color-primary-6);
    }
  `,

  buttonPrimaryOutline: css`
    background-color: var(--color-neutral-0);
    border-color: var(--color-primary-6);
    color: var(--color-primary-6);

    &:hover:not(:disabled) {
      background-color: var(--color-primary-1);
      box-shadow: 0 0 0 2px var(--color-primary-2);
    }

    &:active {
      background-color: var(--color-neutral-0);
      border-color: var(--color-primary-6);
      box-shadow: none;
    }

    &:focus {
      background-color: var(--color-neutral-0);
      border-color: var(--color-primary-6);
      box-shadow:
        0 0 0 1px var(--color-neutral-0),
        0 0 0 3px var(--color-primary-6);
    }
  `,

  buttonPrimaryGhost: css`
    background-color: transparent;
    border-color: transparent;
    color: var(--color-primary-6);

    &:hover:not(:disabled) {
      background-color: var(--color-primary-1);
      box-shadow: 0 0 0 2px var(--color-primary-2);
    }

    &:active {
      background-color: transparent;
    }

    &:focus {
      box-shadow:
        0 0 0 1px var(--color-neutral-0),
        0 0 0 3px var(--color-primary-6);
    }
  `,

  buttonSecondaryOutline: css`
    background-color: var(--color-neutral-0);
    border-color: var(--color-element-border);
    color: var(--color-neutral-9);

    &:hover:not(:disabled) {
      background-color: var(--color-neutral-1);
      box-shadow: 0 0 0 2px var(--color-primary-2);
    }

    &:active {
      background-color: var(--color-neutral-0);
    }

    &:focus {
      box-shadow:
        0 0 0 1px var(--color-neutral-0),
        0 0 0 3px var(--color-primary-6);
    }
  `,

  buttonSecondaryGhost: css`
    background-color: transparent;
    border-color: transparent;
    color: var(--color-neutral-9);

    &:hover:not(:disabled) {
      background-color: var(--color-neutral-1);
      box-shadow: 0 0 0 2px var(--color-primary-2);
    }

    &:active {
      background-color: var(--color-neutral-1);
    }

    &:focus {
      box-shadow:
        0 0 0 1px var(--color-neutral-0),
        0 0 0 3px var(--color-primary-6);
    }
  `,

  buttonDangerFill: css`
    background-color: var(--color-danger-6);
    border-color: var(--color-danger-6);
    color: var(--color-neutral-1);

    &:hover:not(:disabled) {
      background-color: var(--color-danger-7);
      border-color: var(--color-danger-7);
      box-shadow: 0 0 0 2px var(--color-primary-2);
    }

    &:active {
      background-color: var(--color-danger-6);
      border-color: var(--color-danger-6);
    }

    &:focus {
      background-color: var(--color-danger-6);
      border-color: var(--color-danger-6);
      box-shadow:
        0 0 0 1px var(--color-neutral-0),
        0 0 0 3px var(--color-primary-6);
    }
  `,

  buttonDangerOutline: css`
    background-color: var(--color-neutral-0);
    border-color: var(--color-danger-6);
    color: var(--color-danger-6);

    &:hover:not(:disabled) {
      background-color: var(--color-danger-1);
      border-color: var(--color-danger-6);
      box-shadow: 0 0 0 2px var(--color-primary-2);
    }

    &:active {
      background-color: var(--color-neutral-0);
      border-color: var(--color-danger-6);
    }

    &:focus {
      box-shadow:
        0 0 0 1px var(--color-neutral-0),
        0 0 0 3px var(--color-primary-6);
    }
  `,

  buttonDangerGhost: css`
    background-color: transparent;
    border-color: transparent;
    color: var(--color-danger-6);

    &:hover:not(:disabled) {
      background-color: var(--color-danger-1);
      box-shadow: 0 0 0 2px var(--color-primary-2);
    }

    &:active {
      background-color: transparent;
    }

    &:focus {
      box-shadow:
        0 0 0 1px var(--color-neutral-0),
        0 0 0 3px var(--color-primary-6);
    }
  `,
};

export { buttonVariantStyle, BUTTON_VARIANT, BUTTON_APPEARANCE };
export type { ButtonVariant, ButtonAppearance };
