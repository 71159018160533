import { EnabledFeatureMap, useEnabledFeatureMap } from '~/common/hooks/use-enabled-feature-map';

export const useFeatureGuard = (requiredFeatures: Array<keyof EnabledFeatureMap>) => {
  const { data: enabledFeatureMap, isValidating } = useEnabledFeatureMap();
  if (requiredFeatures.length === 0 || isValidating) return false;
  const enabled = requiredFeatures.every(
    (featureName) => enabledFeatureMap && enabledFeatureMap[featureName],
  );
  return enabled;
};
