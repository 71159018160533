import React, { ReactNode } from 'react';
import { css } from '@emotion/react';

type Props = {
  children?: ReactNode;
  id?: string;
};

const ModalBody: React.FC<Props> = React.memo(function ModalBody({ children, id }) {
  return (
    <div css={styles.modalBody} id={id}>
      {children}
    </div>
  );
});

const styles = {
  modalBody: css`
    padding: var(--spacing-8);
    overflow-y: auto;
  `,
};

export { ModalBody };
