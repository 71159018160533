import Head from 'next/head';
import React, { memo, ReactNode } from 'react';
import { css } from '@emotion/react';
import { HeaderInfoProvider } from '~/common/components/layout/HeaderInfoProvider';
import { EnabledFeatureMap } from '~/common/hooks/use-enabled-feature-map';
import { AppHeader } from '~/common/components/layout/app-header/AppHeader';
import { Navigation } from '~/common/components/layout/navigation/Navigation';
import { LoginUserInfo } from '~/common/providers/auth/login-user';
import { DrawerManager } from '~/common/components/drawer';

type LoginUserInfoForAppLayout = Pick<LoginUserInfo, 'firstName' | 'lastName'>;

type Tenant = {
  id: string;
  name: string;
};

type AppLayoutProps = {
  children: ReactNode;
  loginUser: LoginUserInfoForAppLayout;
  logout: () => void;
  currentUrl: string;
  enabledFeatureMap: EnabledFeatureMap;
  tenant: Tenant;
};

const AppLayout: React.FC<AppLayoutProps> = memo(function AppLayout({
  children,
  loginUser,
  logout,
  currentUrl,
  enabledFeatureMap,
  tenant,
}) {
  return (
    <div css={styles.container}>
      <Head>
        <title />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <HeaderInfoProvider>
        <DrawerManager css={styles.body}>{children}</DrawerManager>
        <div css={styles.appHeaderContainer}>
          <AppHeader onLogout={logout} loginUser={loginUser} tenant={tenant} />
        </div>
        <div css={styles.navigationContainer}>
          <Navigation enabledFeatureMap={enabledFeatureMap} currentUrl={currentUrl}></Navigation>
        </div>
      </HeaderInfoProvider>
    </div>
  );
});

const HEADER_HEIGHT = '56px';

const styles = {
  container: css`
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-rows: ${HEADER_HEIGHT} calc(100vh - ${HEADER_HEIGHT});
    grid-template-columns: max-content 1fr;
    justify-content: start;
    overflow: hidden;
  `,
  navigationContainer: css`
    min-width: ${HEADER_HEIGHT};
    grid-row: 1 / 2;
    grid-column: 1;
  `,
  appHeaderContainer: css`
    grid-row: 1;
    grid-column: 2 / 3;
  `,
  body: css`
    grid-row: 2;
    grid-column: 2 / 3;
    overflow: hidden;
    position: relative;
    z-index: 0;
  `,
};

export { AppLayout };
export type { AppLayoutProps, LoginUserInfoForAppLayout, Tenant };
