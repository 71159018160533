import React, { FC, PropsWithChildren } from 'react';
import { css, SerializedStyles } from '@emotion/react';

type Props = PropsWithChildren<{
  width?: '80vw' | '672px';
  ariaLabelledby?: string;
  ariaDescribedby?: string;
}>;

const Modal: FC<Props> = ({ children, width = '672px', ariaLabelledby, ariaDescribedby }) => {
  return (
    <div
      css={[styles.modal, widthStyle(width)]}
      role="dialog"
      aria-modal="true"
      aria-labelledby={ariaLabelledby}
      aria-describedby={ariaDescribedby}
    >
      {children}
    </div>
  );
};

const widthStyle = (width: string): SerializedStyles => css`
  width: ${width};
`;

const styles = {
  modal: css`
    background-color: var(--color-base-background);
    border-radius: 4px;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  `,
};

export { Modal };
