/**
 * Popover を使う際、Popover の子コンポーネントで状態を更新する際に使用する関数。
 *
 * 状態 (show) を子コンポーネントの onClick などで更新すると再レンダリングが発生してしまい、クリックイベントの Bubbling/Capturing が途中で中断される可能性がある。
 * その場合、他の Popover の onClickOutside が発火しなくなる。
 * これを防ぐため、setTimeout を使って状態更新をマクロタスクとして遅延させ、イベント伝播が完了した後に状態を更新する。
 *
 * ※ setTimeout で実行した関数はマクロタスクキューにプッシュされ、次のイベントループで実行される
 */
const deferStateUpdate = (updater: () => void) => {
  setTimeout(updater);
};

export { deferStateUpdate };
