import React, { useMemo } from 'react';
import { Database, Layers, Lock, Settings } from 'react-feather';
import { Role } from '~/common/providers/auth/role';
import { financialSheetsAllowedRoles } from '~/features/financial-sheets/shared/financial-sheets-allowed-roles';
import { EnabledFeatureMap } from '~/common/hooks/use-enabled-feature-map';
import {
  analyticsMenuAllowedRoles,
  analyticsMenuViewAccountAllowedRoles,
} from '~/features/analytics-menu/analytics-menu-allowed-role';
import { planningAllowedRoles } from '~/features/plannings/index/plannings-allowed-roles';
import { allocationsAllowedRoles } from '~/features/allocation/shared/allocation-allowed-roles';
import { analyticsDataDownloadAllowedRoles } from '~/features/analytics-menu/analytics-data-download/analytics-data-download-allowed-role';
import { uploadAllowedRoles } from '~/features/upload/common/upload-allowed-role';
import * as roles from '~/features/settings/settings-allowed-roles';
import { Tooltip } from '~/common/components/tooltip';
import { Icon } from '~/common/components/icon';
import { planUpdateRequestMappingAllowedRoles } from '~/features/plan-update-request-mapping/plan-update-request-mapping-allowed-roles';
import { planUpdateRequestFormatAllowedRoles } from '~/features/plan-update-request-format/plan-update-request-format-allowed-roles';
import { planUpdateRequestFileAllowedRoles } from '~/features/plan-update-request-file/plan-update-request-file-allowed-roles';
import { workforceMasterAllowedRoles } from '~/features/workforce-planning/workforces/workforce-master-allowed-roles';
import { workforcePlanningAllowedRoles } from '~/features/workforce-planning/workforce-planning-allowed-roles';
import { workforceActualsAllowedRole } from '~/features/workforce-planning/workforce-actuals/workforce-actuals-allowed-role';
import { workforcePlanningItemConversionRuleAllowedRoles } from '~/features/workforce-planning/to-financial-item/workforce-planning-item-conversion-rule-allowed-roles';
import { personListAllowedRoles } from '~/features/workforce-planning/person-list/person-list-allowed-roles';
import { settingsViewPermissionsAllowedRoles } from '~/features/settings/settings-allowed-roles';
import { workforcePlanningSimulationAllowedRoles } from '~/features/workforce-planning/simulation/workforce-planning-simulation-allowed-roles';
import { unitAmountAllowedRoles } from '~/features/workforce-planning/unit-amount/unit-amount-allowed-roles';
import { useRoleGuard } from '~/common/providers/auth/use-role-guard';
import { useFeatureGuard } from '~/common/guards/use-feature-guard';
import type { NavigationMenuItem } from '~/common/components/layout/navigation/NavigationBody';
import { CURRENCIES_ALLOWED_ROLES } from '~/features/currencies/currencies-allowed-roles';
import { useMyRole } from '~/common/providers/auth/use-login-user';

type UseNavigationItemsProps = {
  currentUrl: string;
  enabledFeatureMap: EnabledFeatureMap;
};

const useNavigationItems = ({ currentUrl, enabledFeatureMap }: UseNavigationItemsProps) => {
  const isCurrentPage = (targetUrl: string) => {
    if (targetUrl === currentUrl) return true;
    return currentUrl.startsWith(`${targetUrl}/`); // NOTE: 子ページに遷移してもActiveになるように末尾に`/`を入れている
  };
  const myRole = useMyRole();

  const isShowDashboardMenu = useMemo(
    () =>
      myRole &&
      (analyticsMenuAllowedRoles.includes(myRole) ||
        (analyticsMenuViewAccountAllowedRoles.includes(myRole) &&
          enabledFeatureMap.isTableauDashboardEnabled)),
    [myRole, enabledFeatureMap.isTableauDashboardEnabled],
  );

  const isAnalyticsDataDownloadLocked = useMemo(
    () => !(myRole === 'LOGLASS_SYSTEM_ADMIN' || enabledFeatureMap?.isAnalyticsDataDownloadEnabled),
    [myRole, enabledFeatureMap?.isAnalyticsDataDownloadEnabled],
  );

  const isFinancialSheetHistoryAndDepartmentEnabled = useMemo(
    () => enabledFeatureMap?.isFinancialSheetHistoryAndDepartmentEnabled,
    [enabledFeatureMap?.isFinancialSheetHistoryAndDepartmentEnabled],
  );

  const permittedFinancialSheets = useRoleGuard(financialSheetsAllowedRoles);
  const permittedAnalyticsDataDownload = useRoleGuard(analyticsDataDownloadAllowedRoles);

  const permittedPlanning = useRoleGuard(planningAllowedRoles); // 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'CORP_PLANNING', 'MANAGER'
  const permittedSettingsPlans = useRoleGuard(roles.settingsPlansAllowedRoles); // 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'CORP_PLANNING'
  const permittedWorkforcePlanningSimulation = useRoleGuard(
    workforcePlanningSimulationAllowedRoles,
  );
  const permittedPlanUpdateRequestFile = useRoleGuard(planUpdateRequestFileAllowedRoles); // 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'CORP_PLANNING'
  const permittedPlanUpdateRequestFormat = useRoleGuard(planUpdateRequestFormatAllowedRoles);
  const permittedPlanUpdateRequestMapping = useRoleGuard(planUpdateRequestMappingAllowedRoles); // 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'CORP_PLANNING'
  const permittedUpload = useRoleGuard(uploadAllowedRoles); // 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'CORP_PLANNING'
  const permittedWorkforceActuals = useRoleGuard(workforceActualsAllowedRole); // 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN'
  const permittedAllocations = useRoleGuard(allocationsAllowedRoles); // 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'CORP_PLANNING'
  const permittedWorkforcePlanning = useRoleGuard(workforcePlanningAllowedRoles); // 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN'
  const permittedWorkforceToFinancialItem = useRoleGuard(
    workforcePlanningItemConversionRuleAllowedRoles,
  ); // 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN'

  const permittedCurrenciesAllowedRoles = useRoleGuard(CURRENCIES_ALLOWED_ROLES); // 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'CORP_PLANNING'

  const permittedMasterManagement = useRoleGuard(MASTER_MANAGEMENT_ALLOWED_ROLES); // 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'CORP_PLANNING', 'IT_STAFF'
  const permittedSettingsOrganization = useRoleGuard(roles.settingsOrganizationAllowedRoles); // 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'CORP_PLANNING', 'IT_STAFF'
  const permittedSettingsAccountTree = useRoleGuard(roles.settingsAccountTreeAllowedRoles); // 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'CORP_PLANNING'
  const permittedSettingsDimensions = useRoleGuard(roles.settingsDimensionsAllowedRoles); // 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'CORP_PLANNING'
  const permittedPersonList = useRoleGuard(personListAllowedRoles);
  const permittedUnitAmount = useRoleGuard(unitAmountAllowedRoles);
  const permittedWorkforceMaster = useRoleGuard(workforceMasterAllowedRoles);

  const permittedSettings = useRoleGuard(SETTINGS_ALLOWED_ROLES); // 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'IT_STAFF'
  const permittedSettingsViewPermissions = useRoleGuard(settingsViewPermissionsAllowedRoles);

  const isWorkforcePlanningEnabled = useFeatureGuard(['isWorkforcePlanningEnabled']);
  const isViewPermissionEnabled = useFeatureGuard(['isViewPermissionEnabled']);
  const isForeignCurrencyEnabled = useFeatureGuard(['isForeignCurrencyEnabled']);

  const items: NavigationMenuItem[] = [
    permittedFinancialSheets && {
      key: 'financial-sheets',
      text: '経営分析',
      icon: <img alt="spreadsheeticon" src="/assets/icons/spreadsheet.svg" />,
      children: [
        {
          key: 'financial-sheets-reports',
          text: 'レポート',
          targetUrl: '/financial-sheets/reports',
          isActive: isCurrentPage('/financial-sheets/reports'),
        },
        //外貨機能が有効になっている場合、ナビゲーションアイテムは非表示となる
        isFinancialSheetHistoryAndDepartmentEnabled &&
          !isForeignCurrencyEnabled && {
            key: 'financial-sheets-history',
            text: '推移表',
            targetUrl: '/financial-sheets/history',
            isActive: isCurrentPage('/financial-sheets/history'),
          },
        !isForeignCurrencyEnabled && {
          key: 'financial-sheets-comparison',
          text: '対比表',
          targetUrl: '/financial-sheets/comparison',
          isActive: isCurrentPage('/financial-sheets/comparison'),
        },
        isFinancialSheetHistoryAndDepartmentEnabled &&
          !isForeignCurrencyEnabled && {
            key: 'financial-sheets-department',
            text: '部署別表',
            targetUrl: '/financial-sheets/department',
            isActive: isCurrentPage('/financial-sheets/department'),
          },
        isFinancialSheetHistoryAndDepartmentEnabled &&
          !isForeignCurrencyEnabled && {
            key: 'financial-sheets-department-comparison',
            text: '部署別対比表',
            targetUrl: '/financial-sheets/department-comparison',
            isActive: isCurrentPage('/financial-sheets/department-comparison'),
          },
        enabledFeatureMap.isFinancialSimulatorEnabled && {
          key: 'financial-simulations',
          text: 'シミュレーション',
          targetUrl: '/financial-simulations',
          isActive: isCurrentPage('/financial-simulations'),
        },
        isShowDashboardMenu && {
          key: 'dashboard',
          text: 'ダッシュボード',
          targetUrl: '/dashboard',
          isActive: isCurrentPage('/dashboard'),
          suffix: !enabledFeatureMap?.isTableauDashboardEnabled ? <LockedSuffix /> : undefined,
        },
        permittedAnalyticsDataDownload && {
          key: 'analytics-data-download',
          text: 'データダウンロード',
          targetUrl: '/analytics-data-download',
          isActive: isCurrentPage('/analytics-data-download'),
          suffix: isAnalyticsDataDownloadLocked ? <LockedSuffix /> : undefined,
        },
      ].filter(truthy),
    },
    permittedPlanning && {
      key: 'planning',
      text: '経営データ更新',
      icon: <Layers />,
      children: [
        {
          key: '予算・見込',
          id: 'budgets-forecasts',
          text: '予算・見込',
          type: 'group' as const,
          children: [
            permittedSettingsPlans && {
              key: 'plans',
              text: '計画',
              targetUrl: '/plans',
              isActive: isCurrentPage('/plans'),
            },
            isWorkforcePlanningEnabled &&
              permittedWorkforcePlanningSimulation && {
                key: 'workforce-planning-simulations',
                text: '人員計画シミュレーション',
                targetUrl: '/workforce-planning/simulations',
                isActive: isCurrentPage('/workforce-planning/simulations'),
              },
            {
              key: 'plannings',
              text: '編成プロジェクト',
              targetUrl: '/plannings',
              isActive: isCurrentPage('/plannings'),
            },
            permittedPlanUpdateRequestFile && {
              key: 'plan-update-request-files',
              text: '申請済ファイル',
              targetUrl: '/plan-update-request-files',
              isActive: isCurrentPage('/plan-update-request-files'),
            },
            permittedPlanUpdateRequestFormat && {
              key: 'plan-update-request-formats',
              text: '申請フォーマット',
              targetUrl: '/plan-update-request-formats',
              isActive: isCurrentPage('/plan-update-request-formats'),
            },
            permittedPlanUpdateRequestMapping && {
              key: 'plan-update-request-mappings',
              text: '取込・出力設定',
              targetUrl: '/plan-update-request-mappings',
              isActive: isCurrentPage('/plan-update-request-mappings'),
            },
          ].filter(truthy),
        },
        permittedUpload && {
          key: 'actuals',
          id: 'actuals',
          text: '実績',
          type: 'group' as const,
          children: [
            {
              key: 'upload-financial-actuals',
              text: '財務実績',
              targetUrl: '/upload/financial-actuals',
              isActive: isCurrentPage('/upload/financial-actuals'),
            },
            {
              key: 'upload-non-financial-actuals',
              text: '非財務実績',
              targetUrl: '/upload/non-financial-actuals',
              isActive: isCurrentPage('/upload/non-financial-actuals'),
            },
            isWorkforcePlanningEnabled &&
              permittedWorkforceActuals && {
                key: 'upload-workforce/actuals',
                text: '工数実績',
                targetUrl: '/upload/workforce/actuals',
                isActive: isCurrentPage('/upload/workforce/actuals'),
              },
            {
              key: 'actuals-import-mappings',
              text: '取込設定',
              targetUrl: '/actuals-import-mappings',
              isActive: isCurrentPage('/actuals-import-mappings'),
            },
            {
              key: 'process-settings',
              text: '加工設定',
              targetUrl: '/process-settings',
              isActive: isCurrentPage('/process-settings'),
            },
          ].filter(truthy),
        },
        permittedAllocations && {
          key: 'allocations',
          id: 'allocations',
          text: '振替・配賦',
          type: 'group' as const,
          children: [
            {
              key: 'allocations-standards',
              text: '配賦基準',
              targetUrl: '/allocations/standards',
              isActive: isCurrentPage('/allocations/standards'),
            },
            {
              key: 'allocations-rules',
              text: '振替・配賦ルール',
              targetUrl: '/allocations/rules',
              isActive: isCurrentPage('/allocations/rules'),
            },
            {
              key: 'allocations-strategies',
              text: '実行計画',
              targetUrl: '/allocations/strategies',
              isActive: isCurrentPage('/allocations/strategies'),
            },
          ].filter(truthy),
        },
        isWorkforcePlanningEnabled &&
          permittedWorkforcePlanning &&
          permittedWorkforceToFinancialItem && {
            key: 'workforce-planning',
            id: 'workforce-planning',
            text: '人件費変換',
            type: 'group' as const,
            children: [
              {
                key: 'workforce-planning-to-financial-item/base-salary-increase',
                text: '昇給',
                targetUrl: '/workforce-planning/to-financial-item/base-salary-increase',
                isActive: isCurrentPage(
                  '/workforce-planning/to-financial-item/base-salary-increase',
                ),
              },
              {
                key: 'workforce-planning-to-financial-item-conversion-rules',
                text: '科目変換ルール',
                targetUrl: '/workforce-planning/to-financial-item/conversion-rules',
                isActive: isCurrentPage('/workforce-planning/to-financial-item/conversion-rules'),
              },
            ].filter(truthy),
          },
      ].filter(truthy),
    },
    isForeignCurrencyEnabled &&
      permittedCurrenciesAllowedRoles && {
        key: 'data-processing',
        text: 'データ加工',
        icon: <Database />,
        children: [
          {
            id: 'currencies',
            key: 'currencies',
            text: '為替換算',
            type: 'group' as const,
            children: [
              {
                id: 'nav-local-currency',
                key: 'currencies-local-currency',
                text: '現地通貨',
                targetUrl: '/currencies/local-currency',
                isActive: isCurrentPage('/currencies/local-currency'),
              },
              {
                id: 'nav-rates',
                key: 'currencies-rates',
                text: '為替レート',
                targetUrl: '/currencies/rates',
                isActive: isCurrentPage('/currencies/rates'),
              },
            ].filter(truthy),
          },
        ].filter(truthy),
      },
    permittedMasterManagement && {
      key: 'master',
      text: 'マスタ管理',
      icon: <img alt="spreadsheeticon" src="/assets/icons/allocation.svg" />,
      children: [
        permittedSettingsOrganization && {
          // LOG-8924 KARTE でチュートリアルを作るために実験的に追加。後ほど削除する
          id: 'nav-organization',
          key: 'master-organization',
          text: '組織',
          targetUrl: '/master/organization',
          isActive: isCurrentPage('/master/organization'),
        },
        permittedSettingsAccountTree && {
          key: 'accounts',
          id: 'accounts',
          text: '科目',
          type: 'group' as const,
          children: [
            {
              // LOG-8924 KARTE でチュートリアルを作るために実験的に追加。後ほど削除する
              id: 'nav-account-tree',
              key: 'master-account-pl',
              text: '財務科目',
              targetUrl: '/master/account/pl',
              isActive: isCurrentPage('/master/account/pl'),
            },
            {
              key: 'master-account-non-financial',
              text: '非財務科目',
              targetUrl: '/master/account/non-financial',
              isActive: isCurrentPage('/master/account/non-financial'),
            },
            {
              key: 'master-account-calculated-account',
              text: '計算科目',
              targetUrl: '/master/account/calculated-account',
              isActive: isCurrentPage('/master/account/calculated-account'),
            },
          ].filter(truthy),
        },
        permittedSettingsDimensions && {
          key: 'master-dimensions',
          text: '分析軸',
          targetUrl: '/master/dimensions',
          isActive: isCurrentPage('/master/dimensions'),
        },
        isWorkforcePlanningEnabled &&
          permittedWorkforcePlanning &&
          permittedPersonList && {
            key: 'workforce-planning-person-list',
            text: '人員',
            targetUrl: '/workforce-planning/person-list',
            isActive: isCurrentPage('/workforce-planning/person-list'),
          },
        isWorkforcePlanningEnabled &&
          permittedWorkforcePlanning &&
          permittedUnitAmount && {
            key: 'workforce-planning-unit-amounts',
            text: '人件費単価',
            targetUrl: '/workforce-planning/unit-amounts',
            isActive: isCurrentPage('/workforce-planning/unit-amounts'),
          },
        isWorkforcePlanningEnabled &&
          permittedWorkforcePlanning &&
          permittedWorkforceMaster && {
            key: 'workforce-planning-workforce-slots',
            text: '人員計画枠',
            targetUrl: '/workforce-planning/workforce-slots',
            isActive: isCurrentPage('/workforce-planning/workforce-slots'),
          },
        isWorkforcePlanningEnabled &&
          permittedWorkforcePlanning &&
          permittedWorkforceMaster && {
            key: 'workforce-planning-workforce-actual-import-mapping-settings',
            text: '取込設定',
            targetUrl: '/workforce-planning/workforce-actual-import-mapping-settings',
            isActive: isCurrentPage('/workforce-planning/workforce-actual-import-mapping-settings'),
          },
      ].filter(truthy),
    },
    permittedSettings && {
      key: 'settings',
      text: '設定',
      icon: <Settings />,
      children: [
        {
          key: 'settings-tenant',
          text: '会社設定',
          targetUrl: '/settings/tenant',
          isActive: isCurrentPage('/settings/tenant'),
        },
        {
          key: 'settings-users',
          text: 'ユーザー管理',
          targetUrl: '/settings/users',
          isActive: isCurrentPage('/settings/users'),
        },
        isViewPermissionEnabled &&
          permittedSettingsViewPermissions && {
            key: 'settings-view-permissions',
            text: '閲覧権限管理',
            targetUrl: '/settings/view-permissions',
            isActive: isCurrentPage('/settings/view-permissions'),
          },
        isWorkforcePlanningEnabled &&
          permittedWorkforcePlanning && {
            key: 'workforce-planning-unit-amount-viewers',
            text: '人員情報閲覧管理',
            targetUrl: '/workforce-planning/unit-amount-viewers',
            isActive: isCurrentPage('/workforce-planning/unit-amount-viewers'),
          },
      ].filter(truthy),
    },
  ].filter(truthy);

  return items;
};

const LockedSuffix = () => (
  <div>
    <Tooltip
      message={'この機能をご利用されたい場合には、サポート担当までご相談ください'}
      position={'top'}
    >
      <Icon size={'s'} color={'dark'}>
        <Lock />
      </Icon>
    </Tooltip>
  </div>
);

// マスタ管理にアクセスできるロール群
const MASTER_MANAGEMENT_ALLOWED_ROLES: Role[] = [
  'LOGLASS_SYSTEM_ADMIN',
  'TENANT_ADMIN',
  'CORP_PLANNING',
  'IT_STAFF',
];

// 設定にアクセスできるロール群
const SETTINGS_ALLOWED_ROLES: Role[] = ['LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'IT_STAFF'];

type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T;

function truthy<T>(value: T): value is Truthy<T> {
  return !!value;
}

export { useNavigationItems };
