import React from 'react';
import { css, SerializedStyles } from '@emotion/react';

type DividerProps = {
  horizontal?: boolean;
  color?: Color;
  className?: string;
};

type Color = 'darkGray' | 'gray';

const Divider = React.memo(function Divider({
  horizontal,
  color = 'darkGray',
  className,
}: DividerProps): JSX.Element {
  return (
    <div
      className={className}
      css={[horizontal ? styles['horizontal'] : styles['vertical'], colorStyle(color)]}
    />
  );
});

const colorStyle = (color: Color): SerializedStyles => {
  switch (color) {
    case 'darkGray':
      return css`
        border-color: var(--color-element-border);
      `;

    case 'gray':
      return css`
        border-color: var(--color-neutral-3);
      `;
  }
};

const styles = {
  horizontal: css`
    border-top: 1px solid;
    flex-grow: 1;
  `,
  vertical: css`
    border-left: 1px solid;
  `,
};

export { Divider };
export type { DividerProps };
