import React from 'react';
import { css } from '@emotion/react';
import { LogOut, Settings } from 'react-feather';
import { useAuth0 } from '@auth0/auth0-react';
import { InternalLink } from '~/common/components/link';
import { AdminSideBarItem } from '~/common/components/admin-layout/admin-sidebar/AdminSidebarItem';
import { env } from '~/app/environment';

type Props = {
  currentUrl: string;
};

const AdminSideBar: React.FC<Props> = React.memo(function AdminSideBar({ currentUrl }) {
  const { logout } = useAuth0();
  const isActive = (targetUrl: string) => {
    if (targetUrl === currentUrl) return true;

    return currentUrl.toString() === `${targetUrl}/`;
  };
  const onClickLogout = async () => {
    logout({
      clientId: env()?.adminAuth0.client_id,
      logoutParams: {
        returnTo: `${window.location.origin}/admin/login`,
      },
    });
  };

  return (
    <div css={styles.sidebar}>
      <div css={styles.itemContainer}>
        <InternalLink href="/admin">
          <AdminSideBarItem
            icon={<Settings />}
            text="テナント管理"
            isActive={isActive('/admin') || isActive('/admin/demonstrations')}
          />
        </InternalLink>
        <div
          css={css`
            ${styles.itemContainer};
            cursor: pointer;
          `}
          onClick={onClickLogout}
        >
          <AdminSideBarItem icon={<LogOut />} text="ログアウト" />
        </div>
      </div>
    </div>
  );
});

const styles = {
  sidebar: css`
    width: 100%;
    height: 100%;
    background-color: var(--color-base-background);
    overflow-y: auto;
    position: relative;
  `,

  itemContainer: css`
    width: 100%;
    height: 52px;
    position: relative;
  `,
};

export { AdminSideBar };
