import type { FC, PropsWithChildren } from 'react';
import { css } from '@emotion/react';

type Size = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl';
type Variant = `body-${Size}` | `body-${Size}-bold`;
type Color =
  | 'normal'
  | 'normal-inverse'
  | 'annotation'
  | 'active'
  | 'info'
  | 'success'
  | 'warning'
  | 'error';

type Props = {
  id?: string;
  /** font のスタイルを指定する（body の use-case のみ） */
  variant?: Variant;
  color?: Color;
  /**
   * `white-space: pre-wrap` の設定
   *
   * - **true**: 連続するホワイトスペース: そのまま、行の折り返し: 改行文字、</br>
   * - **falsy**: デフォルト、連続するホワイトスペース: 統合、行の折り返し: 自動かつ改行文字はホワイトスペースとして扱う
   **/
  preWrap?: boolean;
  /**
   * `word-break: break-all` の設定
   *
   * - **true**: 単語の途中で改行する
   * - **falsy**: デフォルト、規定の改行規則に従う
   **/
  breakAll?: boolean;
};

/** 文章を表示するためのコンポーネント */
const Text: FC<PropsWithChildren<Props>> = ({
  id,
  variant = 'body-s',
  color = 'normal',
  children,
  ...props
}) => (
  <p id={id} css={styles({ variant, color, ...props })}>
    {children}
  </p>
);

const styles = ({ variant, color, preWrap, breakAll }: Omit<Props, 'id'>) => css`
  font: var(--font-${variant});
  color: var(--color-text-${color});
  ${preWrap ? 'white-space: pre-wrap' : ''};
  ${breakAll ? 'word-break: break-all' : ''};
`;

export { Text };
