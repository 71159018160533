import { memo, ReactNode } from 'react';
import { css } from '@emotion/react';
import { Span } from '~/common/components/span';
import { InternalLink } from '~/common/components/link';

type Props = {
  id?: string;
  text: string;
  targetUrl: string;
  isActive: boolean;
  suffix?: ReactNode;
};

const NavigationThirdItem = memo(function NavigationThirdItem({
  id,
  text,
  targetUrl,
  isActive,
  suffix,
}: Props) {
  return (
    <InternalLink id={id} href={targetUrl}>
      <div css={[styles.subItem, isActive ? styles.subItemActive : undefined]}>
        <div css={styles.subItemIndent}>
          <Span level={2}>{text}</Span>
        </div>
        {suffix}
      </div>
    </InternalLink>
  );
});

const styles = {
  subItem: css`
    border-radius: var(--border-radius-m);
    padding: var(--spacing-3);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 300ms;

    &:hover {
      background-color: var(--color-neutral-3);
    }
  `,
  subItemIndent: css`
    padding-left: 36px;
  `,
  subItemActive: css`
    background-color: var(--color-neutral-4);
  `,
};

export { NavigationThirdItem };
