import React, { useId, useState } from 'react';
import { Info } from 'react-feather';
import { css } from '@emotion/react';
import { ModalHeader } from '~/common/components/modal/ModalHeader';
import { Spinner } from '~/common/components/spinner';
import { ModalBody } from '~/common/components/modal/ModalBody';
import { Text } from '~/common/components/text';
import { ModalFooter } from '~/common/components/modal/ModalFooter';
import { Modal } from '~/common/components/modal/Modal';
import { useHandleHttpError } from '~/common/hooks/http-error/use-handle-http-error';
import { Icon } from '~/common/components/icon';

type ConfirmModalProps = {
  title: string;
  message: string;
  okButtonLabel: string;
  cancelButtonLabel?: string;
  isDangerOkButton?: boolean;
  onOk: () => Promise<void>;
  closeModal: () => void;
  infoMessage?: string;
};

const ConfirmModal = React.memo(function ConfirmModal({
  title,
  message,
  okButtonLabel,
  cancelButtonLabel,
  isDangerOkButton,
  onOk,
  closeModal,
  infoMessage,
}: ConfirmModalProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { handleHttpError } = useHandleHttpError();
  const id = useId();
  const headingId = `${id}-header`;
  const bodyId = `${id}-body`;

  const confirmAndSubmit = async () => {
    try {
      setIsSubmitting(true);
      await onOk();
      closeModal();
    } catch (e) {
      handleHttpError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal ariaLabelledby={headingId} ariaDescribedby={bodyId}>
      <ModalHeader onClose={closeModal} title={title} headingId={headingId} />
      <Spinner show={isSubmitting}>
        <ModalBody id={bodyId}>
          <Text preWrap>{message}</Text>
          {infoMessage && (
            <div css={styles.infoMessage}>
              <div css={styles.infoMessageIcon}>
                <Icon color={'info'} size={'s'}>
                  <Info />
                </Icon>
              </div>
              <div css={styles.infoMessageText}>
                <Text color="info" preWrap>
                  {infoMessage}
                </Text>
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter
          okButtonLabel={okButtonLabel}
          isDangerOkButton={isDangerOkButton}
          onOk={confirmAndSubmit}
          onCancel={closeModal}
          cancelButtonLabel={cancelButtonLabel}
          ariaLabelledby={headingId}
        />
      </Spinner>
    </Modal>
  );
});

const styles = {
  infoMessage: css`
    display: flex;
    align-items: flex-start;
    padding-top: var(--spacing-4);
  `,
  infoMessageIcon: css`
    margin-top: var(--spacing-1);
  `,
  infoMessageText: css`
    padding-left: var(--spacing-2);
  `,
};

export { ConfirmModal };
export type { ConfirmModalProps };
