import React, { memo } from 'react';
import { EnabledFeatureMap } from '~/common/hooks/use-enabled-feature-map';
import { NavigationStatus } from '~/common/components/layout/navigation/navigation-control';
import { NavigationBody } from '~/common/components/layout/navigation/NavigationBody';
import { useNavigationItems } from '~/common/components/layout/navigation/use-navigation-items';

type NavigationBodyContainerProps = {
  currentUrl: string;
  enabledFeatureMap: EnabledFeatureMap;
  navigationStatus: NavigationStatus;
};

const NavigationBodyContainer = memo(function NavigationBodyContainer({
  currentUrl,
  enabledFeatureMap,
  navigationStatus,
}: NavigationBodyContainerProps) {
  const items = useNavigationItems({ currentUrl, enabledFeatureMap });
  return <NavigationBody items={items} navigationStatus={navigationStatus} />;
});

export { NavigationBodyContainer };
export type { NavigationBodyContainerProps };
